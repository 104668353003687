import React from "react"
import tw, { styled, css } from "twin.macro"
import "styled-components/macro"
import _ from "lodash"
import { abbreviate } from "../utils"
import WidgetCard from "./WidgetCard"
import { Link } from "gatsby"

const Content = styled.div`
  ${tw`flex flex-col 
    sm:flex-row lg:flex-col`}
`

const Section = styled.div`
  ${tw`border-gray-300`}

  &:first-of-type {
    ${tw`
        sm:w-4/6 lg:w-full
        pb-4 sm:pb-0 sm:pr-4 lg:pr-0 lg:pb-4
        border-b-[1px] sm:border-b-0 sm:border-r-[1px] lg:border-r-0 lg:border-b-[1px]
    `}
  }

  &:nth-of-type(2) {
    ${tw`
        pt-4 sm:pt-0 sm:pl-4 lg:pl-0 lg:pt-4
        `}
  }
`

const InningStats = styled.div`
  ${tw`flex justify-between gap-4`}
`

// Unsure how to achieve making both tables take up equal width table cells where they also collectively take up the whole
// width of their container other than setting the table cells some arbitrary big width value as shown below
const StatsTable = styled.table`
  td {
    ${tw`w-96 text-center`}
  }

  &:first-of-type {
    td:first-child {
      ${tw`text-left font-semibold`}
    }
  }
`

const PitchingStatsContainer = styled.div`
  ${tw`
    h-full flex justify-between gap-2 
    flex-row sm:flex-col lg:flex-row
    `}
`

const PitchingStats = styled.span`
  ${tw`
    text-sm flex 
    flex-col sm:flex-row lg:flex-col
    `}
`

const InningStatsWidget = ({
  score,
  inningRuns,
  hits,
  errors,
  homeTeam,
  awayTeam,
  pitchingStats,
  showInningStatsSection,
  showPitchingStatsSection,
}) => {
  const abbrevHome = _.get(
    homeTeam,
    "frontmatter.location.abbreviation",
    ""
  ).toUpperCase()
  const abbrevAway = _.get(
    awayTeam,
    "frontmatter.location.abbreviation",
    ""
  ).toUpperCase()

  const homeInningRuns = _.get(inningRuns, "home", [])
  const awayInningRuns = _.get(inningRuns, "away", [])
  const maxInningsCount = _.max([homeInningRuns.length, awayInningRuns.length])

  const homeRHE = [
    _.get(score, "home"),
    _.get(hits, "home"),
    _.get(errors, "home"),
  ]
  const awayRHE = [
    _.get(score, "away"),
    _.get(hits, "away"),
    _.get(errors, "away"),
  ]
  const awayTeamName = _.get(awayTeam, "frontmatter.name", "")
  const homeTeamName = _.get(homeTeam, "frontmatter.name", "")

  return (
    <WidgetCard fit={showPitchingStatsSection && !showInningStatsSection}>
      <Content>
        {showInningStatsSection && (
          <Section>
            <InningStats>
              <StatsTable>
                <thead>
                  <tr>
                    <th />
                    {[...Array(maxInningsCount).keys()].map(idx => (
                      <th>{idx + 1}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {[
                    [awayTeamName, ...awayInningRuns],
                    [homeTeamName, ...homeInningRuns],
                  ].map(inningStats => (
                    <tr>
                      {inningStats.map(run => (
                        <td>{run}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </StatsTable>
              <StatsTable>
                <thead>
                  <tr>
                    <th>R</th>
                    <th>H</th>
                    <th>E</th>
                  </tr>
                </thead>
                <tbody>
                  {[awayRHE, homeRHE].map(rhe => (
                    <tr>
                      {rhe.map(val => {
                        return <td>{val}</td>
                      })}
                    </tr>
                  ))}
                </tbody>
              </StatsTable>
            </InningStats>
          </Section>
        )}
        {showPitchingStatsSection && (
          <Section>
            <PitchingStatsContainer>
              {Object.keys(pitchingStats).map(stat => {
                const statData = pitchingStats[stat]
                const statType = Array.from(stat)[0].toUpperCase()

                const player = _.get(statData, "player")
                const era = _.get(statData, "era")
                const seasonWins = _.get(statData, "seasonRecord.wins")
                const seasonLosses = _.get(statData, "seasonRecord.losses")

                const incompleteStatData = [
                  player,
                  era,
                  seasonWins,
                  seasonLosses,
                ].some(datum => {
                  if (_.isString(datum)) {
                    return _.isEmpty(datum)
                  }
                  return false
                })

                if (incompleteStatData) {
                  return null
                }

                const playerLastName = _.capitalize(player.split("-")[1])
                return (
                  // <PitchingStats to={`../../../roster/${player}`}>
                  <PitchingStats>
                    <div tw="font-semibold pr-2">
                      {statType}: {playerLastName}
                    </div>
                    <div>
                      {seasonWins} - {seasonLosses}{" "}
                      <span tw="text-gray-300">|</span> {era} ERA
                    </div>
                  </PitchingStats>
                )
              })}
            </PitchingStatsContainer>
          </Section>
        )}
      </Content>
    </WidgetCard>
  )
}

export default InningStatsWidget
